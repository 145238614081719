<template>
  <b-card v-if="data" class="card-transaction" no-body>
    <b-card-header>
      <b-card-title v-if=" this.$store.state.userData.typeC == 'Services'">Bookings</b-card-title>
      <b-card-title v-else >Orders</b-card-title>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="flat-secondary"
        @click="goToOrders"
      >
        View all
      </b-button>
    </b-card-header>

    <b-card-body>
      <div
        v-for="dashboardOrders in data"
        :key="dashboardOrders.firstName"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              circle
              size="40"
              variant="primary"
              :text="dashboardOrders.firstName.charAt(0).toUpperCase()"
            >
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ dashboardOrders.firstName }}
            </h6>
            <small>{{ 'AED ' + dashboardOrders.total }}</small>
          </b-media-body>
        </b-media>
        <!-- <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>

          <b-dropdown-item @click="DenyOrder(dashboardOrders.orderId)" href="#">
            <feather-icon icon="XIcon" />
            <span class="align-middle ml-50">Reject order</span>
          </b-dropdown-item>
        </b-dropdown> -->
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import { BButton } from 'bootstrap-vue';
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    //BDropdown,
   // BDropdownItem,
    BButton,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToOrders()
    {
      if(this.$store.state.userData.typeC == 'Services')
      {
      this.$router.push('/apps/bookings');
      }
      else 
      {
      this.$router.push('apps/allorders/list');
      }
    },
    DenyOrder(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reject it!',
      }).then(result => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id=' +
              id +
              '&check=false',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then(response => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Rejected!',
                  'the order has been rejected.',
                  'success',
                ).then(res => {
                  this.$router.push('/apps/orders/list');
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>
